<template>
  <div class="gg-containe">
    <div class="row">
      <div style="margin-rihgt:20px">
        <i class="el-icon-caret-left el-icon" @click="handleSub('year')" />{{
          year
        }}年<i class="el-icon-caret-right el-icon" @click="handleAdd('year')" />
      </div>
      <div>
        <i class="el-icon-caret-left el-icon" @click="handleSub('month')" />{{
          month
        }}月<i
          class="el-icon-caret-right el-icon"
          @click="handleAdd('month')"
        />
      </div>
    </div>
    <div style="width:250px;margin:10px 0;display: flex;align-items: center;">
      <div style="width:100px">医生姓名: </div>
      <el-input
      size="mini"
        v-model="searchParams.doctor_name"
        @change="getList('restPage')"
        placeholder="请输入内容"
        class="input"
      ></el-input>
    </div>
    <el-table
      border
      style="width: 100%"
      size="mini"
      v-loading="listLoading"
      :header-cell-style="{ 'text-align': 'center' }"
      :data="tableData"
      height="725"
      @cell-click="edit"
    >
      <el-table-column type="index" align="center" width="50" />
      <el-table-column
        align="center"
        prop="doctor_name"
        label="医生姓名"
        width="125"
        fixed="left"
      >
      </el-table-column>
      <el-table-column
        :label="item.day + item.week"
        width="auto"
        align="center"
        v-for="(item, index) in curMonth"
        :key="index"
      >
        <template slot-scope="scope">
          <div
            v-for="(item1, index1) in tableData"
            :key="index1"
            class="font-color"
          >
            {{
              scope.row.doctor_id == item1.doctor_id
                ? item1[item["ymd"]]["schedu_name"]
                : ""
            }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />
    <el-dialog :visible.sync="dialogVisible" width="18%" :show-close="false">
      <div class="dialog-content">
        <div class="btn-top">
          <el-radio v-model="info.type" label="1" disabled>按天排班</el-radio>
          <el-radio v-model="info.type" label="2" disabled
            >固定周期排班</el-radio
          >
        </div>
        <div style="font-weight: bold;margin:20px 0;">
          {{ info.type == 2 ? "从该天开始排班至月底" : "修改该员工当天班次" }}
        </div>
        <div style="margin-bottom:20px;display: flex;" v-if="info.type == 2">
          <div style="font-weight: bold;margin-right:30px;width:100px">
            固定周期
          </div>
          <el-select
            v-model="info.week"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="item in weekList"
              :key="item.index"
              :label="item.week"
              :value="item.index"
            >
            </el-option>
          </el-select>
        </div>
        <div style="display: flex;">
          <div style="font-weight: bold;margin-right:90px;">班次</div>
          <div class="middle">
            <div
              v-for="item in classList"
              :key="item.id"
              :class="[
                info.schedu_id == item.id
                  ? 'active select-list'
                  : 'select-list',
              ]"
              @click="handleClass(item)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div>
          <el-button
            @click="handleCancel"
            size="small"
            type="danger"
            v-if="curDay.schedu_id != 0"
            >取消排班</el-button
          >
        </div>
        <div>
          <el-button @click="dialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button type="primary" @click="handleSave" size="small"
            >保 存</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import {
  getCalendar,
  postCalendar,
  putCalendar,
  getSchedus,
} from "@/api/rowWorks/index";
export default {
  name: "formCalendar",
  //监视
  watch: {
    "info.type"(val) {
      if (val == 1) {
        this;
      }
    },
  },
  components: {
    Pagination,
  },
  data() {
    return {
      searchFlg: false, //搜索的字段显示隐藏
      curDay: {}, //当前选择的日期
      info: {
        schedu_id: null,
        type: "1",
      },
      type: "", //edit:编辑 add:新增
      weekList: [
        {
          week: "周一",
          index: 1,
        },
        {
          week: "周二",
          index: 2,
        },
        {
          week: "周三",
          index: 3,
        },
        {
          week: "周四",
          index: 4,
        },
        {
          week: "周五",
          index: 5,
        },
        {
          week: "周六",
          index: 6,
        },
        {
          week: "周日",
          index: 0,
        },
      ],
      dialogVisible: false,
      curMonth: [],
      ymd: [],
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: "+id",
      },
      searchParams: {
        doctor_name: "",
      },
      value: "",
      total: 0,
      year: "", //年
      month: "", //月
      tableData: [],
      listLoading: false,
      classList: [], //班次列表
    };
  },
  created() {
    //计算出当前年和月
    let date = new Date();
    this.year = date.getFullYear();
    this.month = date.getMonth() + 1;
    this.getList();
    this._getSchedus();
  },
  methods: {
    //搜索状态
    searchShow() {
      this.searchFlg = !this.searchFlg;
    },
    //取消排班
    handleCancel() {
      this.info.schedu_id = 0;
      postCalendar(this.info).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.getList();
          this.dialogVisible = false;
          this.info = {
            schedu_id: null,
            type: "1",
          };
          this.$message({
            message: "取消成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
          });
        }
      });
    },
    //选择星期
    selectChange(e) {
      console.log(e);
      this.info.week = e;
    },
    //保存排次
    handleSave(row) {
      console.log(this.info);
      if (this.type == "add") {
        postCalendar(this.info).then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.getList();
            this.dialogVisible = false;
            this.info = {
              schedu_id: null,
              type: "1",
            };
            this.$message({
              message: "保存成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.msg,
              type: "error",
            });
          }
        });
      } else {
        let data = {};
        data.work_id = this.curDay.work_id;
        data.type = "1";
        data.schedu_id = this.info.schedu_id;
        putCalendar(data).then((res) => {
          if (res.code == 200) {
            this.getList();
            this.dialogVisible = false;
            this.info = {
              schedu_id: null,
              type: "1",
            };
            this.$message({
              message: "编辑成功",
              type: "success",
            });
          }
        });
      }
    },
    //选择班次
    handleClass(e) {
      this.info.schedu_id = e.id;
      console.log(e, this.classId);
    },
    //获取班次列表
    _getSchedus() {
      getSchedus().then((res) => {
        console.log(res);
        this.classList = res.data;
      });
      console.log(this.classList);
    },
    edit(row, column, cell, event) {
      console.log("edit", row, column.label, cell, event);
      this.info.doctor_id = row.doctor_id;
      this.info.doctor_name = row.doctor_name;
      this.info.year = this.year;
      this.info.month = this.month;
      //拿到column.label的第一个
      if (column.label == "医生姓名") {
        this.info.type = "2";
        this.info.schedu_id = null;
        this.type = "add";
        console.log(this, "==1111=");
      } else {
        this.info.type = "1";
        this.info.day = column.label.slice(0, 2);
        //如果this.info.day以0开头就去掉0
        if (this.info.day.slice(0, 1) == 0) {
          this.info.day = this.info.day.slice(1, 2);
        }
        let day = column.label.slice(0, 2);
        console.log(day, "==========");
        for (let key in row) {
          if (day == key.slice(-2)) {
            console.log(key, row[key], this.info, "==========1111");
            this.curDay = row[key];
            if (this.curDay.schedu_id != 0) this.type = "edit";
            else this.type = "add";
            this.info.schedu_id = row[key].schedu_id;
          }
        }
      }
      this.dialogVisible = true;
    },
    monthInfo() {
      // 获取当前日期,和周数
      let date = new Date();
      //拿到当前月份的所有天数和对应星期,放到数组中,{day:1,week:星期一}
      let curMonth = [];
      let year = this.year;
      let month = this.month;
      let day = new Date(year, month, 0).getDate();
      for (let i = 1; i <= day; i++) {
        let week = new Date(year, month - 1, i).getDay();
        let weekStr = "";
        switch (week) {
          case 0:
            weekStr = "星期日";
            break;
          case 1:
            weekStr = "星期一";
            break;
          case 2:
            weekStr = "星期二";
            break;
          case 3:
            weekStr = "星期三";
            break;
          case 4:
            weekStr = "星期四";
            break;
          case 5:
            weekStr = "星期五";
            break;
          case 6:
            weekStr = "星期六";
            break;
        }
        //取出本月的所有日期.
        let y = year;
        let m = month;
        let d = i;
        if (m < 10) {
          m = "0" + m;
        }
        if (d < 10) {
          d = "0" + d;
        }
        curMonth.push({
          day: i >= 10 ? i + "日" : "0" + i + "日",
          week: weekStr,
          index: week,
          ymd: y + "" + m + "" + d,
        });
      }
      this.curMonth = curMonth;
      console.log(this.curMonth);
    },
    getList(type) {
      if (type == "restPage") {
        this.listQuery.page = 1;
      }
      console.log("getList触发");
      this.listLoading = true;
      this.searchParams.page = this.listQuery.page;
      this.searchParams.page_size = this.listQuery.limit;
      this.searchParams.year = this.year;
      this.searchParams.month = this.month;
      getCalendar(this.searchParams)
        .then((response) => {
          let data = response;
          this.monthInfo();
          console.log(
            this.curMonth,
            // data["data"]["list"][0][this.ymd[0]]["schedu_id"],
            "========================="
          );
          if (data.code == 200) {
            let _data = data.data;
            this.total = _data.total;
            this.tableData = _data.list;
          }
          setTimeout(() => {
            this.listLoading = false;
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
        });
    },
    //减少年份月份
    handleSub(type) {
      console.log(type);
      if (type == "year") {
        this.year--;
        if (this.year < 1900) {
          this.year = 1900;
        }
        this.getList();
      } else {
        this.month--;
        if (this.month < 1) {
          this.month = 12;
          this.year--;
        }
        this.getList();
      }
    },
    //增加年份月份
    handleAdd(type) {
      console.log(type);
      if (type == "year") {
        this.year++;
        if (this.year > 2100) {
          this.year = 2100;
        }
        this.getList();
      } else {
        this.month++;
        if (this.month > 12) {
          this.month = 1;
          this.year++;
        }
        this.getList();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.gg-containe {
  display: flex;
  flex-direction: column;
  height: 73vh;
}
.el-icon {
  cursor: pointer;
  color: #469fe8;
  font-size: 30px;
}
.row {
  font-size: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.dialog-content {
  box-sizing: border-box;
  padding: 10px 20px;
}
.btn-top {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}
.middle {
  display: flex;
  flex-direction: column !important;
}
.select-list {
  text-align: center;
  width: 150px;
  height: 30px;
  line-height: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin-bottom: 10px;
}
.active {
  background-color: #3dbbd1 !important;
}
.dialog-footer {
  display: flex;
  justify-content: space-between;
}
.font-color {
  color: #40a9ed;
  cursor: pointer;
}
.icon-search {
  cursor: pointer;
  font-size: 18px;
}
</style>
